export const API_BASE_URL = window.location.host === "hrm.frontdeskbd.com" ? "https://fdbhrmapi.gdnweb.online" : "https://fdbhrmapi.gdnweb.online";

export const monthsInWords = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const getCurrentMonthInWords = () => {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();

    return monthsInWords[currentMonthIndex];
};
